import React from "react"
import {
  BlogsListContainer,
  BlogsListCover,
  BlogsSliderContainer,
} from "./styles"
import { BlogTitle } from "../latestBlog/styles"
import Blog from "../../common/blog-card/Blog"
import { useMediaQuery } from "react-responsive"
import CarouselSlider from "../../slider/Slider"

const BlogsList = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  })

  return (
    <BlogsListContainer>
      <div className="container">
        <BlogTitle>All posts</BlogTitle>

        {isMobile ? (
          <BlogsSliderContainer>
            <CarouselSlider
              slidesToShow={1}
              slidesToScroll={1}
              showSlideDots={false}
            >
              <Blog />
              <Blog />
              <Blog />
              <Blog />
              <Blog />
              <Blog />
            </CarouselSlider>
          </BlogsSliderContainer>
        ) : (
          <BlogsListCover>
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
            <Blog />
          </BlogsListCover>
        )}
      </div>
    </BlogsListContainer>
  )
}

export default BlogsList

import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import TitleBanner from "../components/titleBanner/TitleBanner"
import BlogsList from "../components/blog/blogs-list/BlogsList"
import LatestBlog from "../components/blog/latestBlog/LatestBlog"
import Subscription from "../components/home/subscriptionSection/Subscription"

let BlogPage = ({ data }) => {
  return (
    <>
      <SEO
        title="All BIM & CAD Modeling Courses | BIM & CAD Training Center"
        description="Ace Industrial Academy conducts BIM courses for Autocad 2D/3D, Revit, Synchro 4D, OpenPlant, and more"
      />
      <TitleBanner
        title="Blogs"
        description="Read and enjoy our collection of articles on the skills-in-demand by the industry.        "
        withOnlyDescription
        hasPlainBanner
      />

      <LatestBlog />

      <BlogsList />
      <Subscription />
    </>
  )
}

export const allQuery = graphql`
  query allBlogQuery {
    allContentfulAiaBlog {
      edges {
        node {
          id
          slug
          title
          description
          coverPhoto {
            fluid(
              maxWidth: 400
              maxHeight: 250
              resizingBehavior: SCALE
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default BlogPage

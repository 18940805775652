import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  BlogContainer,
  BlogContent,
  BlogImgCover,
  BlogLink,
  BlogList,
  BlogListImgCover,
  BlogSideContent,
  BlogTitle,
  BlogTop,
  BlogTopLeft,
  BlogTopRight,
} from "./styles"
import {
  Link as GatsbyLink,
  graphql,
  useStaticQuery,
} from "gatsby"
import Img from "gatsby-image"

const LatestBlog = () => {
  const data = useStaticQuery(graphql`
    query {
      blog: file(relativePath: { eq: "blog-image.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bloglist: file(
        relativePath: { eq: "blog-list-image.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BlogContainer>
      <div className="container">
        <BlogTitle>Latest posts</BlogTitle>
        <BlogTop>
          <BlogTopLeft>
            <BlogImgCover>
              <Img
                fluid={data.blog.childImageSharp.fluid}
              />
            </BlogImgCover>
            <BlogContent>
              <h6>11th January 2022</h6>
              <h2>
                How to Apply for Absentee Payroll Funding
              </h2>
              <p>
                We go through the key steps required for
                employers to apply for skillsfuture absentee
                payroll funding via the enterprisejobskills
                website.
              </p>
              <BlogLink>
                <GatsbyLink to="/blog/revit-skillsfuture-funding">
                  Read post
                  <span>
                    <StaticImage
                      src="../../../assets/images/link.png"
                      alt=""
                    />
                  </span>
                </GatsbyLink>
              </BlogLink>
            </BlogContent>
          </BlogTopLeft>

          <BlogTopRight>
            <BlogList>
              <BlogListImgCover>
                <Img
                  fluid={
                    data.bloglist.childImageSharp.fluid
                  }
                />
              </BlogListImgCover>
              <BlogSideContent>
                <h6>11th January 2022</h6>
                <h2>
                  How to Apply for Absentee Payroll Funding
                </h2>
                <BlogLink>
                  <GatsbyLink to="/blog/revit-skillsfuture-funding">
                    Read post
                    <span>
                      <StaticImage
                        src="../../../assets/images/link.png"
                        alt=""
                      />
                    </span>
                  </GatsbyLink>
                </BlogLink>
              </BlogSideContent>
            </BlogList>
            <BlogList>
              <BlogListImgCover>
                <Img
                  fluid={
                    data.bloglist.childImageSharp.fluid
                  }
                />
              </BlogListImgCover>
              <BlogSideContent>
                <h6>11th January 2022</h6>
                <h2>
                  How to Apply for Absentee Payroll Funding
                </h2>
                <BlogLink>
                  <GatsbyLink to="/blog/revit-skillsfuture-funding">
                    Read post
                    <span>
                      <StaticImage
                        src="../../../assets/images/link.png"
                        alt=""
                      />
                    </span>
                  </GatsbyLink>
                </BlogLink>
              </BlogSideContent>
            </BlogList>
            <BlogList>
              <BlogListImgCover>
                <Img
                  fluid={
                    data.bloglist.childImageSharp.fluid
                  }
                />
              </BlogListImgCover>
              <BlogSideContent>
                <h6>11th January 2022</h6>
                <h2>
                  How to Apply for Absentee Payroll Funding
                </h2>
                <BlogLink>
                  <GatsbyLink to="/blog/revit-skillsfuture-funding">
                    Read post
                    <span>
                      <StaticImage
                        src="../../../assets/images/link.png"
                        alt=""
                      />
                    </span>
                  </GatsbyLink>
                </BlogLink>
              </BlogSideContent>
            </BlogList>
          </BlogTopRight>
        </BlogTop>
      </div>
    </BlogContainer>
  )
}

export default LatestBlog
